export const divisibility = [
  {
    id: 0,
    name: 'Делимый лот',
    boolean: true
  },
  {
    id: 1,
    name: 'Не делимый лот',
    boolean: false
  }
]

export const typeTP = [
  {
    name: 'Открытый конкурс',
    id: 0
  }
]

export const listColorStatusLot = new Map([
  ['Создан', 'green'],
  ['опубликован', 'green'],
  ['cancel', 'red'],
  ['открыт', ' green'],
  ['торги начались', 'green']
])
