<template>
  <a
    :class="['categories__link', classLink, smallFontSize ? 'categories__link--fsz-sm' : '']"
  >
    {{ paintCategoryName() }}
    <VIcon
      v-if="isCloseEmit"
      @click="onClose"
      icon-name="CloseLot"
      width="14"
      height="14"
    />
  </a>
</template>

<script>
import VIcon from '@/components/ui/icon/VIcon'
export default {
  components: { VIcon },
  emits: ['close'],
  props: {
    category: {
      type: Object,
      required: true
    },
    smallFontSize: {
      type: Boolean,
      required: false,
      default: false
    },
    isCloseEmit: {
      type: Boolean,
      required: false
    },
    classLink: {
      type: String,
      required: false
    }
  },
  setup (props, { emit }) {
    const paintCategoryName = () => {
      const arr = props.category.name.split('')
      const maxSymbol = props.isCloseEmit ? 26 : 29
      if (arr.length > maxSymbol) {
        return props.category.name.slice(0, maxSymbol) + '...'
      } else {
        return props.category.name
      }
    }

    const onClose = () => {
      emit('close', props.category)
    }

    return {
      paintCategoryName,
      onClose
    }
  }
}
</script>
