<template>
  <div :class="['categories', isMaxWidth ? 'max-width' : '']" v-if="categories?.length">
    <h4 class="categories__subtitle">Категории ТП:</h4>
    <ul class="categories__list">
      <li class="categories__item" v-for="category in getCategories" :key="category">
        <VCategoriesItem
          :classLink="classLink"
          :category="category"
          :is-close-emit="isCloseEmit"
          :small-font-size="smallFontSize"
          @close="onClose"
        />
      </li>
      <VMore
        v-if="isMore && categories.length > 7"
        :counter="counterCategories"
        @open="toggleOpen"
      />
    </ul>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import VCategoriesItem from '@/components/list/CategoriesList/VCategoriesItem'
import VMore from '@/components/ui/buttons/VMore'

export default {
  emits: ['close'],
  props: {
    smallFontSize: {
      type: Boolean,
      required: false,
      default: false
    },
    categories: {
      type: Array,
      required: true
    },
    classLink: {
      type: String,
      required: false,
      default: 'bg--yellow'
    },
    isCloseEmit: {
      type: Boolean,
      required: false,
      default: false
    },
    isMore: {
      type: Boolean,
      required: false,
      default: false
    },
    isMaxWidth: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup (props, { emit }) {
    const PER_CATEGORIES = 7
    const isOpen = ref(false)
    const counterCategories = ref(0)

    watch(props.categories, () => {
      counterCategories.value = props.categories.length - PER_CATEGORIES
    })

    const getCategories = computed(() => {
      if (!isOpen.value) {
        return props.categories.slice(0, PER_CATEGORIES)
      } else {
        return props.categories
      }
    })

    const toggleOpen = (val) => {
      isOpen.value = val
    }

    const onClose = (cat) => {
      emit('close', cat)
    }

    return {
      toggleOpen,
      onClose,
      getCategories,
      counterCategories
    }
  },
  components: { VMore, VCategoriesItem }
}
</script>
