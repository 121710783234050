<template>
  <div class="mt-20 height-70" >

    <div class="lot__loader height-70" v-if="getIsLoad">
      <SpinnerLoader />
    </div>

    <div v-if="getLotList.length && !getIsLoad">
      <VLot
        v-for="lot in getLotList"
        :key="lot.id"
        :lot="lot"
        :id="lot.id"
        :title="lot?.name"
        :mode="lot?.procedureType?.name"
        :company="lot?.company?.name"
        :position="lot?.position"
        :note="lot.note"
        :statusPublic="lot.status"
        :members="lot?.bidder?.length || 'Нет'"
        :counterLot="lot.id"
        :date-publication="lot.published_at"
        :date-before-start="lot.timeToStart"
        :date-start="lot.started_at"
        :date-end="lot.duration"
        :interaction-button="lot.menu"
        :is-chosen-icon="isChosenIcon"
        :show-member="showMember"
        :is-integration="lot.send"
        :show-status="showStatus"
        :is-favorites="lot?.is_follow"
        @lotClick="toLot"
        class="mb-20"
      />
    </div>

    <div v-else-if="!getLotList.length && !getIsLoad">Лоты не найдены</div>

    <PaginationRefactor @page="handlerPage" />
  </div>
</template>

<script>
import VLot from '@/components/list/LotList/Lot/VLot'
import { computed, reactive, watch } from 'vue'
import { useStore } from 'vuex'
import PaginationRefactor from '@/components/ui/pagination/PaginationRefactor'
import { transformLotObject } from '@/utils/utils'
import { useGetItem } from '@/use/Base/item/useGetItem'
import { itemProps } from '@/mixin/props'
import SpinnerLoader from '@/components/views/Spinner/VSpinnerLoader'
export default {
  emits: ['lot-click', 'page'],
  mixins: [itemProps],
  props: {
    isChosenIcon: {
      type: Boolean,
      required: false,
      default: true
    },
    showStatus: {
      type: Boolean,
      required: false,
      default: true
    },
    showMember: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const category = reactive([])

    const configuration = reactive({
      transformObject: transformLotObject,
      urlSetRows: props.urlSetRows,
      requestIsAuth: props.requestIsAuth,
      urlActionQuery: props.urlActionQuery,
      sort: '-published_at'
    })

    const getUrlActionQuery = computed(() => {
      return props.urlActionQuery
    })

    watch(getUrlActionQuery, () => {
      configuration.urlActionQuery = props.urlActionQuery
    })

    const getLotList = computed(() => {
      return [...store.getters['item/getItems']]
    })

    const getLot = computed(() => {
      return store.getters['lot/getLot']
    })

    watch(getLot, () => {
      if (getLot.value.position.length) {
        getLot.value.position.forEach(p => p.categories.forEach(c => category.push(c.name)))
      }
    })

    const toLot = (id) => {
      emit('lot-click', id)
    }

    return {
      ...useGetItem(emit, 9, configuration),
      getLotList,
      toLot,
      category
    }
  },
  components: {
    PaginationRefactor,
    SpinnerLoader,
    VLot
  }
}
</script>

<style scoped>
.lot__loader {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.height-70 {
  height: 70%;
}
</style>
