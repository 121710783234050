<template>
  <span
    class="counter"
    v-if="!isOpen"
    @click="toggleOpen"
  >
    Ещё:
    <span class="counter--number">
      {{ counter }}
    </span>
    <VIcon
      icon-name="Arrow"
      width="11"
      height="7"
    />
  </span>

  <span
    class="counter"
    v-else
    @click="toggleOpen"
  >
    Свернуть
    <VIcon
      class="rect-180"
      icon-name="Arrow"
      width="11"
      height="7"
    />
  </span>
</template>

<script>
import { ref } from 'vue'
import VIcon from '@/components/ui/icon/VIcon'
export default {
  props: {
    counter: {
      type: [String, Number],
      required: false
    }
  },
  setup (props, { emit }) {
    const isOpen = ref(false)

    const toggleOpen = () => {
      isOpen.value = !isOpen.value
      emit('open', isOpen.value)
    }

    return {
      isOpen,
      toggleOpen
    }
  },
  components: {
    VIcon
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";

.counter {
  margin-left: auto;
  padding-right: 170px;

  display: flex;
  align-items: center;
  color: $color-primary;
  user-select: none;
  cursor: pointer;

  &--number {
    margin: 0 5px;
    padding: 2px 6px;
    background-color: $color-yellow;
    border-radius: 20px;
  }
}

.rect-180 {
  margin-left: 10px;
  transform: rotate(180deg);
}
</style>
