<template>
  <div
    :id="lotId"
    class="lot__menu cursor"
    @click="toggleIsOpen">
    <VIcon
      class="lot__favorites-icon"
      width="4"
      height="28"
      icon-name="MenuLot"
    />

    <VTouchBar
      v-if="isOpen"
      class="menu"
      class-inner="menu__inner"
      class-touch-bar-item="menu__item"
      class-touch-bar-description="menu__description"
      :touch-bar-list="renderTouchBarList()"
      :selected-items="[]"
      @action="onAction"
    >
      <span class="triangle__top"></span>
    </VTouchBar>
  </div>
</template>

<script>
import { ref } from 'vue'
import VIcon from '@/components/ui/icon/VIcon'
import { lotTouchBarList } from '@/utils/touchbar'
import { useCloseOutsideModal } from '@/use/Base/useCloseOutsideModal'
import VTouchBar from '@/components/ui/touchbar/VTouchBar'
import { useStore } from 'vuex'
import { paintMenuByStatus } from '@/utils/lot/function.lot'
export default {
  emits: ['action'],
  props: {
    lotId: {
      type: [String, Number],
      required: true
    }
  },
  setup (props, { emit }) {
    const store = useStore()
    const isOpen = ref(false)

    const toggleIsOpen = () => {
      isOpen.value = true
    }

    const onAction = (emitName) => {
      emit('action', emitName)
    }

    const handleClick = (e) => {
      const lotMenu = e.target.closest('.lot__menu')
      if (lotMenu && Number(lotMenu.id) === Number(props.lotId)) return
      isOpen.value = false
    }

    useCloseOutsideModal(handleClick)

    const renderTouchBarList = () => {
      const list = []
      const lot = store.getters['item/getItems'].find(item => item.id === props.lotId)
      paintMenuByStatus(list, lot.status_id, lotTouchBarList)
      return list
    }
    return {
      toggleIsOpen,
      isOpen,
      onAction,
      renderTouchBarList
    }
  },
  components: {
    VTouchBar,
    VIcon
  }
}
</script>

<style scoped lang="scss">
@import "~@/styles/_variables.scss";
.cursor {
  display: flex;
  justify-content: flex-end;
  width: 20px;
  cursor: pointer;
}
</style>
