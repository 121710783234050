<template>
  <div class="lot__sidebar">
    <div class="lot__icon">
      <div class="lot__counter">Лот {{ counterLot }}</div>

      <img
        v-if="interactionButton && !isIntegration"
        :src="require(`@/assets/icon/lot/${interactionButton}.svg`)"
        :alt="interactionButton" @click="handlerEmit('delete')"
      />

      <VLotMenu
        v-if="!isChosenIcon && isLeader && !$route.fullPath.includes('delete') && !isIntegration && !$route.fullPath.includes('showcase')"
        :lot-id="id"
        @action="handlerEmit"
      />
    </div>

    <VLotFavorites
      class="mb-20"
      v-if="isChosenIcon && !isIntegration"
      :lot-id="id"
      :is-favorites="isFavorites"
    />

    <div class="lot__block">
      <h6 class="lot__date">Дата и время публикации:</h6>
      <p class="lot__text">{{ datePublication ? datePublication : 'После публикации' }}</p>
    </div>
    <div class="lot__block">
      <h6 class="lot__date">Время до старта:</h6>
      <p class="lot__text">{{ dateBeforeStart ? dateBeforeStart : 'Не указано' }}</p>
    </div>
    <div class="lot__block">
      <h6 class="lot__date">Дата и время начала:</h6>
      <p class="lot__text">{{ dateStart ? dateStart : 'Не указано' }}</p>
    </div>
    <div class="lot__block">
      <h6 class="lot__date">Дата и время окончания:</h6>
      <p class="lot__text">{{ dateEnd ? dateEnd : 'Не указано' }}</p>
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import { lotProps } from '@/mixin/props'
import VLotFavorites from '@/components/list/LotList/Lot/Part/VLotFavorites'
import VLotMenu from '@/components/list/LotList/Lot/Part/VLotMenu'
import { LotController } from '@/controllers/LotController'
import { useStore } from 'vuex'

export default {
  props: {
    isFavorites: {
      type: Boolean,
      required: true
    },
    isIntegration: {
      type: Boolean,
      required: false
    }
  },
  mixins: [lotProps],
  setup (props) {
    const lotController = new LotController()
    const store = useStore()

    const isLeader = computed(() => {
      return isOwnerLot() || store.getters['auth/getRole'] === 'admin'
    })

    const isOwnerLot = () => {
      return store.getters['item/getItems'].find(item => +item.id === +props.counterLot).user?.id === store.getters['auth/getUser'].id
    }

    const handlerEmit = async (emitName) => {
      console.log(emitName)
      await lotController[emitName](props.id)
    }

    return {
      isLeader,
      handlerEmit
    }
  },
  components: {
    VLotFavorites,
    VLotMenu
  }
}
</script>
