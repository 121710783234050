<template>
  <div class="lot" :id="id">
    <div class="lot__inner">

      <div class="lot__border">
        <div class="lot__info">
          <h3 class="lot__title" @click="toLot">{{ title }}</h3>

          <div class="lot__margin">
            <h5 class="lot__subtitle">Режим проведения ТП:</h5>
            <p class="lot__text">{{ mode }}</p>
          </div>

          <div class="lot__margin">
            <h5 class="lot__subtitle">Компания:</h5>
            <p class="lot__text">{{ company ? company : 'Компания не была указана' }}</p>
          </div>

          <div class="lot__margin">
            <div class="categories">
              <VCategoriesList
                :categories="categories"
              />
            </div>
          </div>

          <div class="lot__margin">
            <h5 class="lot__subtitle">Примечание:</h5>
            <p class="lot__text">{{ note ? note : 'Не указано' }}</p>
          </div>

          <div class="buttons">
            <button
              v-if="!$route.fullPath.includes('showcase') && !isIntegration"
              class="btn btn-primary btn-bg-green mt-60 mr-20"
              @click="toLot"
            >
              Подробнее
            </button>

            <button
              v-if="checkAuthRole(['admin', 'organizer']) && isOwner"
              class="btn btn-primary btn-long btn-bg-green mt-60"
              @click="copyLot"
            >
              Скопировать шаблон
            </button>

            <button
              v-if="isIntegration"
              class="btn btn-primary btn-long btn-bg-green"
              @click="toChangeLot"
            >
              Редактировать
            </button>
          </div>
        </div>

        <div class="lot__status">
          <div class="lot__status-inner">
            <p class="lot__members" v-if="showMember">
              <VIcon width="22" height="27" icon-name="UsersLot" />
              Участники: {{ members }}
            </p>
          </div>
          <div class="mt-auto" v-if="showStatus">
            <p :class="['lot__dispatch-status', getColorStatus]">{{ statusPublic }}</p>
          </div>
        </div>
      </div>

      <VLotSidebar
        :id="id"
        :counterLot="counterLot"
        :date-publication="datePublication"
        :date-before-start="dateBeforeStart"
        :date-start="dateStart"
        :date-end="dateEnd"
        :interaction-button="interactionButton"
        :is-chosen-icon="isChosenIcon"
        :show-member="showMember"
        :show-status="showStatus"
        :is-favorites="isFavorites"
        :is-integration="isIntegration"
      />

    </div>
  </div>
</template>

<script>
import { computed, inject, onMounted, reactive, ref } from 'vue'
import VIcon from '@/components/ui/icon/VIcon'
import { lotProps } from '@/mixin/props'
import VLotSidebar from '@/components/list/LotList/Lot/Part/VLotSidebar'
import VCategoriesList from '@/components/list/CategoriesList/VCategoriesList'
import { pushCategories, checkAuthRole } from '@/utils/lot/function.lot'
import { listColorStatusLot } from '@/utils/lot/list'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import { LOT_CONTROLLER } from '@/utils/consts'

export default {
  components: { VCategoriesList, VLotSidebar, VIcon },
  emits: ['lot-click'],
  mixins: [lotProps],
  props: {
    showStatus: {
      type: Boolean,
      required: false,
      default: true
    },
    isIntegration: {
      type: Boolean,
      required: false
    },
    showMember: {
      type: Boolean,
      required: true,
      default: true
    },
    title: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      required: true
    },
    company: {
      type: String,
      required: false
    },
    note: {
      type: String,
      required: false
    },
    statusPublic: {
      type: String,
      required: false
    },
    // Включить
    members: {
      type: [Number, String],
      required: false
    },
    lot: {
      type: Object,
      required: false
    },
    isFavorites: {
      type: Boolean,
      required: true
    },
    position: {
      type: Array,
      required: false
    }
  },
  setup (props, { emit }) {
    const categories = reactive([])
    const lotController = inject(LOT_CONTROLLER)
    const store = useStore()
    const isOwner = ref(false)
    const router = useRouter()

    onMounted(() => {
      pushCategories(categories, props.lot)
      if (store.getters['auth/getUser'].id === props.lot.user?.id) {
        isOwner.value = true
      }
    })

    async function copyLot () {
      await lotController.copy(props.lot)
    }

    const getColorStatus = computed(() => {
      return listColorStatusLot.get(props.statusPublic)
    })

    const toLot = () => {
      emit('lot-click', props.id)
    }

    const toChangeLot = () => {
      router.push({ name: 'organizer-create-trade', params: { id: props.lot.id }, query: { integration: 'integration' } })
    }

    return {
      isOwner,
      categories,
      getColorStatus,
      toLot,
      checkAuthRole,
      copyLot,
      toChangeLot
    }
  }
}
</script>

<style>
.buttons {
  display: flex;
}
</style>
